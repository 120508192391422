import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import { SelectedTermsContextProvider } from 'src/Presentation/context/SelectedTermContexts';
import HeaderMainPage from './header/headerMainPage/HeaderMainPage';
import HeaderPage from './header/headerPage/HeaderPage';

interface DashboardLayoutProps {
  layout: string;
}

export default function DashboardLayout(props: DashboardLayoutProps) {
  const { layout } = props;

  return (
    <SelectedTermsContextProvider>
      <Box sx={{ overflowY: 'hidden' }}>
        {layout === 'articles' ? <HeaderMainPage /> : <HeaderPage />}
        <Box sx={{ marginTop: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </SelectedTermsContextProvider>
  );
}

import { Autocomplete, Box, TextField } from '@mui/material';
import { useContext } from 'react';
import { CategorizationDataSourceImpl } from 'src/Data/DataSources/Categorization/CategorizationDataSource';
import { CategorizationRepositoryImpl } from 'src/Data/Repositories/CategorizationRepositoryImpl';
import { useFetchCategorizations } from 'src/Domain/UseCases/useFetchCategorization';
import { IndustryContext } from 'src/Presentation/context/IndustryContext';

export default function AutocompleteSectorsAndIndustries() {
  const { industry, setIndustry } = useContext(IndustryContext);

  const { categorizations } = useFetchCategorizations(
    new CategorizationRepositoryImpl(new CategorizationDataSourceImpl())
  );

  const sectors: any[] = [];
  categorizations?.forEach((sector) => {
    sectors.push({ label: sector.sector.name, value: sector.sector._id });
    sector.industries.forEach((industryItem) => {
      sectors.push({
        label: industryItem.name,
        value: industryItem._id,
        sector_ref: industryItem.sector_ref,
      });
    });
  });

  const handleOpen = () => {
    setIndustry((prevState) => ({
      ...prevState,
      openOptions: true,
    }));
  };

  const handleChangeIndustry = (event: React.ChangeEvent<{}>, value: any) => {
    if (value)
      setIndustry((prevState) => ({
        ...prevState,
        label: value.label,
        sector_ref: value.sector_ref,
        value: value.value,
        openOptions: false,
      }));
    else {
      setIndustry((prevState) => ({
        ...prevState,
        label: '',
        sector_ref: '',
        value: '',
        openOptions: true,
      }));
    }
  };

  const handleClose = () => {
    setIndustry((prevState) => ({
      ...prevState,
      openOptions: false,
    }));
  };

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo3"
      options={sectors}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      sx={{ zIndex: 9900, marginRight: 1 }}
      size="small"
      onChange={handleChangeIndustry}
      onFocus={handleOpen}
      onBlur={handleClose}
      onClose={handleClose}
      onOpen={handleOpen}
      open={industry.openOptions}
      renderOption={(props, option) => (
        <Box component="li" sx={{ p: 0, m: 0 }} {...props}>
          {option.sector_ref !== undefined ? (
            <span style={{ fontSize: 12 }}>{option.label}</span>
          ) : (
            <b>{option.label}</b>
          )}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label="Sectors and Industries" />}
    />
  );
}

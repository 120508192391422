import { Chip, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SelectedTermsContext } from 'src/Presentation/context/SelectedTermContexts';
import { useContext } from 'react';
import './style.css';

export default function TermsMatched() {
  const { selectedTerms, setSelectedTerms } = useContext(SelectedTermsContext);

  const handleDeleteChip = (chipToDelete: string) => () => {
    setSelectedTerms((prevState) => prevState.filter((chip) => chip !== chipToDelete));
  };

  return (
    <>
      {selectedTerms.length > 0 && (
        <Stack direction="column" spacing={1} sx={{ marginLeft: 1 }}>
          <Typography variant="button" color="primary">
            Terms
          </Typography>
          <Stack direction="row" spacing={1}>
            {selectedTerms.map((term, index) => (
              <Chip
                key={index}
                label={term}
                color="primary"
                onDelete={handleDeleteChip(term)}
                deleteIcon={
                  <IconButton size="small" aria-label="delete">
                    <CloseIcon sx={{ fontSize: 15 }} />
                  </IconButton>
                }
              />
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
}

/* eslint-disable no-nested-ternary */
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useContext, useEffect, useState } from 'react';
import './style.css';
import { useFetchDetectTerm } from 'src/Domain/UseCases/useFetchDetectTerm';
import { TermDataSourceImpl } from 'src/Data/DataSources/Term/TermDataSource';
import { TermRepositoryImpl } from 'src/Data/Repositories/TermRepositoryImpl';
import { IndustryContext } from 'src/Presentation/context/IndustryContext';
import { SelectedTermsContext } from 'src/Presentation/context/SelectedTermContexts';

export default function SearchTerms() {
  const { industry } = useContext(IndustryContext);
  const { selectedTerms, setSelectedTerms } = useContext(SelectedTermsContext);
  const [inputText, setInputText] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [termNotFound, setTermNotFound] = useState<boolean>(false);

  const { termDetected, isFetchDetectTermLoading } = useFetchDetectTerm(
    searchTerm,
    new TermRepositoryImpl(new TermDataSourceImpl())
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleBackspaceDelete = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputText.length === 0 && (event.key === 'Backspace' || event.key === 'Delete')) {
      console.log('clean');
    }
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  const handleClick = () => {
    setTermNotFound(false);
    // eslint-disable-next-line no-unused-vars
    setSearchTerm(inputText);
  };

  useEffect(() => {
    if (searchTerm !== '' && termDetected) {
      addTermToSelectedTerms();
    }
  }, [isFetchDetectTermLoading]);

  const addTermToSelectedTerms = () => {
    if (termDetected?.domain === 'industry_term') {
      if (!selectedTerms.includes(termDetected.stored_value))
        setSelectedTerms((prevState) => [...prevState, termDetected.stored_value]);
    } else {
      setTermNotFound(true);
    }
    setInputText('');
    setSearchTerm('');
  };

  return (
    <Box sx={{ display: 'flex', marginTop: 0.5 }}>
      <TextField
        sx={{ flexGrow: 1 }}
        size="small"
        variant="outlined"
        placeholder="Find terms..."
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ padding: 0 }} position="end">
              <Tooltip title={termNotFound ? 'Term not found' : ''} open={termNotFound}>
                <IconButton
                  type="button"
                  sx={{
                    cursor: inputText.length >= 2 ? 'pointer' : 'not-allowed',
                  }}
                  aria-label="Search"
                  disabled={inputText.length < 2}
                  onClick={handleClick}
                >
                  {termDetected && isFetchDetectTermLoading ? (
                    <CircularProgress size={24} />
                  ) : termNotFound ? (
                    <ErrorOutlineIcon color="error" />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        onChange={handleSearchChange}
        onKeyUp={handleBackspaceDelete}
        onKeyDown={handleEnterKeyPress}
        disabled={industry.value === ''}
        value={inputText}
      />
    </Box>
  );
}

// @mui
import { Box, Grid } from '@mui/material';

import Logo from 'src/Presentation/components/logo';

import SearchTerms from './SearchTerms';
import AccountPopover from '../AccountPopover';
import AutocompleteSectorsAndIndustries from './AutocompleteSectorsAndIndustries';
import TermsMatched from './TermsMatched';
import './style.css';

export default function HeaderMainPage() {
  return (
    <Box sx={{ marginX: 2, marginTop: 2 }}>
      <Grid container alignItems="center" spacing={4}>
        <Grid item xs={2}>
          <Logo />
        </Grid>

        <Grid item xs={3}>
          <AutocompleteSectorsAndIndustries />
        </Grid>

        <Grid item xs={3}>
          <SearchTerms />
        </Grid>

        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <AccountPopover />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={3} />

        <Grid className="termMatchedSection" item xs={3}>
          <TermsMatched />
        </Grid>

        <Grid item xs={4} />
      </Grid>
    </Box>
  );
}

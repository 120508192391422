import { Box, Grid } from '@mui/material';
import Logo from 'src/Presentation/components/logo';
import AccountPopover from '../AccountPopover';

export default function HeaderPage() {
  return (
    <Box sx={{ marginTop: 2, marginX: 2 }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={2}>
          <Logo />
        </Grid>

        <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <AccountPopover />
        </Grid>
      </Grid>
    </Box>
  );
}

import { Dispatch, SetStateAction, createContext, useMemo, useState } from 'react';

interface IndustryType {
  label: string;
  sector_ref: string;
  value: string;
  openOptions: boolean;
}

interface IndustryContextType {
  industry: IndustryType;
  setIndustry: Dispatch<SetStateAction<IndustryType>>;
}

const defaultValue: IndustryContextType = {
  industry: {
    label: '',
    sector_ref: '',
    value: '',
    openOptions: false,
  },
  setIndustry: () => {},
};

export const IndustryContext = createContext(defaultValue);

interface IndustryContextProviderProps {
  children: React.ReactNode;
}

export const IndustryContextProvider = (props: IndustryContextProviderProps) => {
  const { children } = props;
  const [industryState, setIndustryState] = useState<IndustryType>(defaultValue.industry);

  const contextValue = useMemo(
    () => ({
      industry: industryState,
      setIndustry: setIndustryState,
    }),
    [industryState]
  );

  return <IndustryContext.Provider value={contextValue}>{children}</IndustryContext.Provider>;
};
